<template>
  <div>
    <b-modal
      id="deactivate-truck"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('DeactivateTruck')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <!-- @shown="" -->
      <p v-if="getRoutersForTrucks.length !==0">
        Routes of this Truck
      </p>
      <p v-else>
        {{ $t("DeactivateTheTruck") }}
      </p>
      <div
        v-for="itemm in getRoutersForTrucks"
        :key="itemm.tourId"
        style="
          background-color: gainsboro;
          border-radius: 8px;
          padding: 10px;
          min-height: 56px;
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

        "
      >
        <div
          style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;"
        >
          <b-form-group
            id="input-group-8"
            label="Route Name: "
            label-for="input-25"
          >
            {{ itemm.tourName }}
          </b-form-group>
          <b-form-group
            id="input-group-8"
            label="Ends in"
            label-for="input-25"
          >
            {{ dateModified(itemm.endShift) }}
          </b-form-group>
          <b-button
            type="button"
            variant="none"
            style="margin-left: 10px"
            class="buttonSubmit"
            @click="fetchTrucks(itemm.storeGLN, itemm.endShift), replaceShow(), getRouteID(itemm.tourId),selectedItemId = itemm.tourId"
          >
            Replace
          </b-button>
          <!-- <b-button
            type="button"
            variant="none"
            style="margin-left: 10px"
            class="buttonSubmit"
            @click="fetchTrucks(), replaceShow(), getRouteID(itemm.tourId), storeeid(itemm.storeId, itemm.endShift), selectedItemId = itemm.tourId"
          >
            Replace
          </b-button> -->
        </div>
        <b-form-group
          v-if="replace == true && itemm.tourId === selectedItemId"
          :id="itemm.tourId"
          style="width: 100%"
          label="Select Free Truck"
          label-for="input-25"
        >
          <vue-select
            id="input-8"
            v-model="truckSelected"
            :options="getFreeTrucks"
            :value="truckId"
            placeholder="Select"
            label="registrationPlate"
          />
          <b-button
            style="margin-top: 10px"
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="editTruckToRoute(truckSelected.truckId)"
          >
            Exchange
          </b-button>
        </b-form-group>

      </div>
      <div style="margin-top: 20px">
        <b-button
          type="button"
          variant="none"
          style="margin-left: 0px"
          class="buttonSubmit"
          :disabled="getRoutersForTrucks.length !== 0"
          @click="onSubmit"
        >
          {{ $t('Deactivate') }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import flatPickr from 'vue-flatpickr-component';
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

// import {
//   required, minLength, alpha, minValue, numeric, alphaNum, email,
// } from 'vuelidate/lib/validators';

export default {
  components: {
    // flatPickr,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['item'],
  data() {
    return {
      truckIDD: '',
      routeID: '',
      storeeGLN: null,
      deactivate: {
        truckId: null,
        status: false,
      },
      endshift: null,
      truckSelected: '',
      selectedItemId: null,
      pageSize: 10,
      pageNumber: 1,
      replace: false,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      roleOptions: ['CEO', 'Sewer', 'TeamLeader', 'Admin', 'Manager', 'PatternMaker', 'Trainee'],
      personalInfoForm: {
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        houseNumber: '',
        street: '',
        country: '',
        passport: '',
        aufenthaltsbewilligung: '',
        ahv: '',
        email: '',
        role: '',
      },
      Selected: {
        brandName: '',
        modelName: '',
        variantName: '',
        registrationPlate: '',
        firstRegistration: '',
        insuranceExpirationDate: '',
        storeId: null,
      },
      store: null,
      workInfoForm: {
        startingDate: '',
        endingDate: '',
        workload: '',
        vacationDays: '',
        employeeType: '',
        role: '',
        salary: '',
      },
    };
  },
  // validations: {
  //   personalInfoForm: {
  //     firstName: {
  //       required,
  //       minLength: minLength(3),
  //       alpha,
  //     },
  //     lastName: {
  //       required,
  //       minLength: minLength(3),
  //       alpha,
  //     },
  //     salutation: {
  //       required,
  //     },
  //     gender: {
  //       required,
  //     },
  //     city: {
  //       required,
  //       minLength: minLength(3),
  //       alpha,
  //     },
  //     postalCode: {
  //       required,
  //       minLength: minLength(3),
  //       alphaNum,
  //     },
  //     doorNumber: {
  //       required,
  //       minLength: minValue(1),
  //       numeric,
  //     },
  //     street: {
  //       required,
  //       minLength: minLength(3),
  //     },
  //     country: {
  //       required,
  //       minLength: minLength(3),
  //       alpha,
  //     },
  //     email: {
  //       required,
  //       email,
  //     },
  //     role: {
  //       required,
  //     },
  //   },
  // },
  computed: {
    ...mapGetters(['getFiliales', 'getTrucksPaginationforDeactive', 'getRoutersForTrucks', 'getFreeTrucks']),
    // getSal() {
    //   return this.$store.state.login.salutation;
    // },
  },
  watch: {
    store: {
      handler(value) {
        if (value.storeId == '') {
          this.Selected.storeId = null;
        }
        this.truck.storeId = value.storeId;
        // console.log('ppp', this.truck.storeId);
      },
    },
    item: {
      handler(value) {
        this.deactivate.truckId = value;
      },
      immediate: true,
    },


  },

  mounted() {
    // this.fetchTrucks();
    // console.log('qq', this.item)
    this.truckIDD = this.item;
    // this.deactivate.truckId = this.item
    // console.log('qq99', this.deactivate.truckId)

    // console.log('qqqaaa', new Date().toJSON().slice(0, 10).replace(/-/g, '-'))


    // console.log('qq11', this.storeeid)
    // console.log('qq22', this.endshift)
    // console.log('test', this.truckIDD)
    // console.log('123', this.getFreeTrucks)
  },
  methods: {
    ...mapActions(['loadTrucksPaginationForDeactive', 'exchangeTruckInATour', 'loadToursForTrucks', 'loadfreeTrucksByStoreId', 'loadFreeTrucks']),
    validateState(name) {
      const { $dirty, $error } = this.$v.personalInfoForm[name];
      return $dirty ? !$error : null;
    },
    fetchTrucks(storeGLNn, endShiftt) {
      // console.log('storeGLN', storeGLNn)
      this.loadFreeTrucks({
        storeGLN: storeGLNn,
        startShift: this.dateModified(new Date().toJSON().slice(0, 10).replace(/-/g, '-')),
        endShift: this.dateModified(endShiftt),
      }).then((res) => {
        console.log(res);
        // this.changeLoadingtoTrue(true);
      });
    },

    getRouteID(value) {
      this.routeID = value
    },
    storeeid(value, value2) {
      // console.log(value, value2)
      this.storeeGLN = value
      this.endshift = value2
    },
    replaceShow(itemId) {
      this.replace = !this.replace;
      this.selectedItemId = itemId;
    },
    // routes() {
    //   this.loadToursForTrucks({
    //     truckId: this.item,
    //     startShift: new Date().toJSON().slice(0, 10).replace(/-/g, '.'),
    //   })
    // },

    async editTruckToRoute(value) {
      // console.log('ww', value)
      await this.exchangeTruckInATour({
        tourId: this.routeID,
        truckId: this.item,
        newTruckId: value,
        successCallback: () => {
          this.loadToursForTrucks({
            truckId: this.item,
            startShift: this.dateModified(new Date().toJSON().slice(0, 10).replace(/-/g, '.')),
          })
          this.loadTrucksPaginationForDeactive({
            storeId: 'UNASSIGNED',
            status: true,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
          // this.loadFreeTrucks({
          //   storeId: this.storeeID,
          //   startShift: this.dateModified(new Date().toJSON().slice(0, 10).replace(/-/g, '-')),
          //   endShift: this.endshift,
          // })
        },
      })
    },

    onSubmit() {
      // this.$v.personalInfoForm.$touch();
      // if (this.$v.personalInfoForm.$anyError) {
      //   return;
      // }
      // console.log('eee', this.deactivate)
      this.$emit('click', this.deactivate);
      this.$refs.modal.hide();
      // this.onReset()
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },

    onReset() {
      // Reset our form values
      this.personalInfoForm.firstName = '';
      this.personalInfoForm.lastName = '';
      this.personalInfoForm.salutation = '';
      this.personalInfoForm.gender = '';
      this.personalInfoForm.city = '';
      this.personalInfoForm.street = '';
      this.personalInfoForm.dateOfBirth = '';
      this.personalInfoForm.postalCode = '';
      this.personalInfoForm.doorNumber = '';
      this.personalInfoForm.country = '';
      this.personalInfoForm.phoneNumber = '';
      this.personalInfoForm.email = '';
      this.personalInfoForm.role = '';
      // Trick to reset/clear native browser form validation state
    },
    dateModified(xdate) {
      // return moment(xdate).format('DD/MM/YYYY - HH:mm');
      return moment(xdate).format('YYYY-MM-DD HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
