<!-- eslint-disable func-names -->
<!-- eslint-disable vue/require-default-prop -->
<!-- eslint-disable vue/require-default-prop -->
<template>
  <div>
    <b-sidebar
      id="edit-truck-toggle"
      ref="modal"
      header-class="headerModali"
      centered
      right
      shadow
      :title="$t('EditTruck')"
      width="50%"
      hide-footer
      @close="onCancel"
    >
      <template #title>
        <strong style="color: #242f6e;">{{ `${$t('EditTruck')}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #242f6e;"
          class="fa fa-close"
          @click="onCancel"
        />
      </template>
      <b-form style="padding-left: 10px;padding-right: 10px;">
        <b-form-group
          id="input-group-2"
          :label="$t('BrandName')"
          label-for="input-2"
        >
          <vue-select
            v-model="truck.brandName"
            required
            :options="[ &quot;Ford&quot;, &quot;Chevrolet&quot;, &quot;Ram&quot;, &quot;GMC&quot;, &quot;Toyota&quot;, &quot;Nissan&quot;, &quot;Jeep&quot;, &quot;Dodge&quot;, &quot;Honda&quot;, &quot;Mitsubishi&quot;, &quot;Isuzu&quot;, &quot;Mazda&quot;, &quot;Volvo&quot;, &quot;Mercedes-Benz&quot;, &quot;Scania&quot;, &quot;MAN&quot;, &quot;Iveco&quot;, &quot;Renault&quot;, &quot;Fuso&quot;, &quot;Hino&quot;]
            "
            placeholder="Select"
          />
          <span
            v-show="formSubmitted && !$v.truck.brandName.required"
            style="color: red"
          >{{ $t('PleaseSelectABrand') }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('Model')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.truck.modelName.$model"
            :state="validateState('modelName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('VariantName')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.truck.variantName.$model"
            :state="validateState('variantName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('LicencePlate')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.truck.registrationPlate.$model"
            :state="validateState('registrationPlate')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('ChasisNumber')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.truck.chassisNumber.$model"
            :state="validateState('registrationPlate')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <!-- <b-form-group
          id="input-group-9"
          label="Date of first registration"
          label-for="input-9"
        >
          <date-picker
            v-model="truck.chassisNumber"
            type="datetime"
            value-type="format"
            placeholder="Select Date"
            :format="'DD-MM-YYYY'"
          />
          <span
            v-show="formSubmitted && !$v.truck.chassisNumber.required"
            style="color: red"
          >Please select a date.</span>
        </b-form-group> -->
        <b-form-group
          id="input-group-9"
          :label="$t('ProduceYear')"
          label-for="input-9"
        >
          <!-- <b-form-input
            id="input-1"
            v-model="$v.truck.year.$model"
            :state="validateState('year')"
            aria-describedby="input-1-live-feedback"
          /> -->
          <date-picker
            v-model="test"
            type="date"
            value-type="format"
            :placeholder="$t('Select')"
            :format="'DD-MM-YYYY'"
          />
          <span
            v-show="formSubmitted && !$v.truck.year.required"
            style="color: red"
          >{{ $t('PleaseSelectADate') }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="$t('Filiale')"
          label-for="input-9"
        >
          <vue-select
            v-model="store"
            v-b-tooltip.hover
            required
            :options="getFiliales"
            label="storeName"
            :value="storeId"
            :placeholder="$t('Select')"
            :disabled="getIsTruckBusy"
            :title="getIsTruckBusy ? $t('NotEditTruck'): ''"
          />
        </b-form-group>
        <pre />
        <div style="width: 110%">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmitAddTruck"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-sidebar>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import moment from 'moment'

import {
  required, minLength,
} from 'vuelidate/lib/validators';

export default {
  components: {
    // flatPickr,
    DatePicker,
  },
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editTruck: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    storeIdd: {
      type: String,
    },
  },
  data() {
    return {
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      test: null,
      roleOptions: ['CEO', 'Sewer', 'TeamLeader', 'Admin', 'Manager', 'PatternMaker', 'Trainee'],
      personalInfoForm: {
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        houseNumber: '',
        street: '',
        country: '',
        passport: '',
        aufenthaltsbewilligung: '',
        ahv: '',
        email: '',
        role: '',
      },
      store: null,
      storeId: null,
      formSubmitted: false,
      truck: {
        truckId: '',
        brandName: '',
        modelName: '',
        variantName: '',
        registrationPlate: '',
        storeId: null,
        year: null,
        chassisNumber: null,
      },
      workInfoForm: {
        startingDate: '',
        endingDate: '',
        workload: '',
        vacationDays: '',
        employeeType: '',
        role: '',
        salary: '',
      },
    };
  },
  validations: {
    truck: {
      brandName: {
        required,
        minLength: minLength(3),
      },
      modelName: {
        required,
        minLength: minLength(3),
      },
      variantName: {
        required,
        minLength: minLength(3),
      },
      registrationPlate: {
        required,
      },
      chassisNumber: {
        required,
      },
      year: {
        required,
      },
      // firstRegistration: {
      //   required,
      // },
      // insuranceExpirationDate: {
      //   required,
      // },
    },
    store: {
      // required,
    },
  },
  computed: {
    ...mapGetters(['getFiliales', 'getIsTruckBusy']),
  },
  watch: {
    'store.storeId': {
      handler(value) {
        this.truck.storeId = value;
      },
      immediate: true,
    },
    item(value) {
      this.store = value
    },
    // eslint-disable-next-line func-names
    'truck.year': function (value) {
      this.test = moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD-MM-YYYY');
    },
    test(value) {
      this.truck.year = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    store: {
      handler(value) {
        if (value == null) {
          this.truck.storeId = null;
        }
        this.truck.storeId = value.storeId;
      },
    },
    storeIdd(value) {
      this.truck.storeId = value
    },
  },
  mounted() {
    // this.truck.storeId = this.storeIdd
    this.truck = this.editTruck;
    this.truck.storeId = this.storeIdd
  },
  created() {
    // this.truck.storeId = this.storeIdd
    this.truck.storeId = this.storeIdd
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.truck[name];
      return $dirty ? !$error : null;
    },
    onSubmitAddTruck() {
      this.formSubmitted = true;

      this.$v.truck.$touch();
      if (this.$v.truck.$anyError) {
        return;
      }
      // const firstRegistration = moment(this.truck.firstRegistration, 'DD-MM-YYYY').format('YYYY-MM-DD');
      // this.truck.firstRegistration = firstRegistration;

      // const insurance = moment(this.truck.insuranceExpirationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      // this.truck.insuranceExpirationDate = insurance;
      this.$emit('edit', this.truck);
      this.$refs.modal.hide();
      this.onReset()
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        // this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        // this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.truck.brandName = '';
      this.truck.modelName = '';
      this.truck.variantName = '';
      this.truck.registrationPlate = '';
      this.truck.storeId = '';
      this.truck.chassisNumber = '';
      this.truck.year = '';
      this.truck.truckId = '';
      this.formSubmitted = false
      this.storeIdd = ''
      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
.mx-datepicker {
    position: relative;
    display: inline-block;
    width: 100%;
}
</style>
